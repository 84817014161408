.chatbot-container {
  position: fixed;
  bottom: 216px;
  right: 60px;
  width: 300px;
  height: 400px;
  z-index: 1000;
  border-radius: 10px;
}

.chatbot-link {
  font-weight: bold;
  color: white !important;
}

.chatbot-link:hover {
  color: black;
  text-decoration: underline;
}

.chatbot-header {
  background-color: #007bff;
  color: white;
  padding: 10px;
  font-weight: bold;
  text-align: center;
  position: relative;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 92%;
}

.emergency-banner {
  background-color: rgb(155, 154, 154);
  color: white;
  text-align: center;
  padding: 5px;
  font-size: 14px;
  width: 92%;
}

.react-chatbot-kit-chat-header {
  display: none;
}

.chatbot-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #0271e7;
  color: white;
  border: none;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  z-index: 100;
  justify-content: center;
}

.tooltip-text {
  font-size: 15px;
}

.chatbot-disclaimer {
  font-size: 12px;
  font-weight: 500;
  color: rgb(98, 98, 98);
  background-color: white;
  padding: 8px;
  text-align: center;
  border-top: 1px solid #ccc;
  position: relative;
  width: 90%;
  bottom: 100px;
}

.chatbot-icon:hover {
  background-color: #0056b3;
}

.react-chatbot-kit-chat-bot-message {
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  max-width: 90%;
}

.react-chatbot-kit-chat-bot-message a {
  word-break: break-all;
  overflow-wrap: break-word;
  display: inline-block;
  max-width: 100%;
  text-decoration: underline;
  color: #5181d9;
  cursor: pointer;
}
