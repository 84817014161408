.card-container {
  min-width: 495px;
  max-width: 520px;
  height: 98%;
  border-radius: 20px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  background: white;
}

@media (max-width: 450px) {
  .card-container {
    max-width: 450px;
    min-width: 100%;
    margin: auto;
  }

  .arrow-btn-container {
    display: flex;
    padding: 10px 10px;
    align-items: center;
  }
}

.media-container {
  display: flex;
  justify-content: space-between;
  width: 80%;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  overflow: hidden;
  margin: 0 auto;
  position: relative;
}

.article-card-link {
  font-size: 0.9em;
}

.media-video-thumbnail {
  position: relative;
  cursor: pointer;
}

.arrow-btn {
  background: #000;
  color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  &:hover {
    cursor: pointer;
  }
}

.news-media-container {
  width: 90%;
}

.card-img {
  border-radius: 20px 20px 0 0;
  height: 200px;
  width: 100%;
}

.no-image-placeholder {
  background-color: rgb(230, 230, 230);
  border-radius: 20px 20px 0 0;
  height: 200px;
  width: 100%;
  display: flex;
  font-size: 2.5rem;
  color: gray;
  font-weight: 800;
  align-items: center;
  justify-content: center;
}
@media (min-width: 450px) {
  .arrow-btn-container {
    display: flex;
    padding: 0px 30px;
    align-items: center;
  }
}

.card-content-container {
  height: 25%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
}
.article-card-heading {
  font-size: 1.2em;
}

.vlog-title {
  padding-top: 10px;
}

@media (min-width: 1650px) {
  .news-media-container {
    width: 80%;
  }
}
